import React from "react";
import style from "./Mdm.module.css";
import Button from "@material-ui/core/Button";
import person from "../../Images/person.png";
import cel2 from "../../Images/cel2.png";
import android from "../../Images/android.png";
import { useMediaQuery } from "@material-ui/core";

export const Mdm = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.item1 : style.item1Mob}>
        <img
          src={person}
          alt="person"
          className={!mobile ? style.person : style.personMob}
        />
        <img
          src={cel2}
          alt="cel2"
          className={!mobile ? style.cel2 : style.cel2Mob}
        />
      </div>
      <div className={!mobile ? style.item2 : style.item2Mob}>
        <div className={style.txtCont}>
          <div className={style.androidcont}>
            <img src={android} className={style.android} alt="" />
          </div>

          <h2 className={!mobile ? style.title : style.titleMob}>
            <strong> Optalock MDM</strong>
            <br />
            What is it and how does it work?
          </h2>
          <p className={!mobile ? "" : style.subtitleMob}>
            Optalock MDM is a solution for managing corporate-owned Android
            devices.With the help of an Android Launcher installed on an empty
            device with extended privileges
          </p>
          <div className={style.list}>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Single Tasks Mode (Kiosk Mode)
              </p>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                GPS tracking
              </p>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Remote access, lock and unlock
              </p>{" "}
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Decide and limit the applications to install on your corporate
                devices
              </p>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
            </p>
          </div>
          <div>
            <Button
              variant="contained"
              className={!mobile ? style.btn : style.btnMob}
              color="primary"
            >
              Know more
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
