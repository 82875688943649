import React from "react";
import { MiniCard } from "./Cards/MiniCard";
import style from "./Slide1.module.css";
import mini1 from "../../Images/mini1.png";
import mini2 from "../../Images/mini2.png";
import mini3 from "../../Images/mini3.png";

export const Slide1 = () => {
  return (
    <div className={style.container}>
      <MiniCard img={mini1} txt={"Home office migration"}></MiniCard>
      <MiniCard img={mini2} txt={"Savings on phone data plan"}></MiniCard>
      <MiniCard img={mini3} txt={"Kiosk Mode"}></MiniCard>
    </div>
  );
};
