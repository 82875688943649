import React from "react";
import style from "./Control.module.css";
import Button from "@material-ui/core/Button";
import img1 from "../../Images/cel1.png";
import back from "../../Images/cel1back.png";
import { useMediaQuery } from "@material-ui/core";

export const Control = () => {
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.item1 : style.item1Mob}>
        <div className={!mobile ? style.texts : style.textsMob}>
          <h1 className={!mobile ? "" : style.titlemob}>
            Control and measure the efficiency of your team
          </h1>

          <h4 className={!mobile ? "" : style.subtitleMob}>
            We are the management, measurement and intelligent remote control
            system in real time, for corporate devices that you were looking
            for.
          </h4>
          <div className={style.btnCont}>
            <Button variant="contained" color="primary">
              <p className={!mobile ? style.btnText : style.btnTextMob}>
                {" "}
                I want to know more
              </p>
            </Button>
          </div>
        </div>
      </div>
      <div className={!mobile ? style.item2 : style.item2Mob}>
        <img
          src={img1}
          alt="cel"
          className={!mobile ? style.cel1 : style.cel1Mob}
        />
        <img
          src={back}
          alt="back"
          className={!mobile ? style.back : style.backMob}
        />
      </div>
    </div>
  );
};
