import { useMediaQuery } from "@material-ui/core";
import React from "react";
import style from "./Stories.module.css";

export const Stories = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={style.container}>
      <div className={!mobile ? style.txtContainer : style.txtContainerMob}>
        <h1 className={!mobile ? style.title : style.titleMob}>
          Success stories
        </h1>
        <p className={!mobile ? style.txt : style.txtMob}>
          Learn how we have helped our clients protect their assets, save money
          and resources.
        </p>
      </div>
    </div>
  );
};
