import style from "./CardsLicense.module.css";
import React from "react";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";

export const CardsLicense = ({
  img,
  price,
  title,
  text1,
  text2,
  text3,
  selected,
  button,
}) => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {!mobile ? (
        <div className={!selected ? style.container : style.containerBlue}>
          <div className={style.content}>
            <img src={img} alt="img" />
            <h4 className={style.price}>{price}</h4>
            <h4 className={style.title}>{title}</h4>
            <p className={style.text}>{text1}</p>
            <p className={style.text}>{text2}</p>
            <p className={style.text}>{text3}</p>
            {button ? (
              <Button variant="contained" color="primary" className={style.btn}>
                Free with annual Optacheck
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div
          className={!selected ? style.containerMob : style.containerBlueMob}
        >
          <div className={style.contentMob}>
            <img src={img} alt="img" />
            <h4 className={style.priceMob}>{price}</h4>
            <h4 className={style.titleMob}>{title}</h4>
            <p className={style.textMob}>{text1}</p>
            <p className={style.textMob}>{text2}</p>
            <p className={style.textMob}>{text3}</p>
            {button ? (
              <Button
                variant="contained"
                color="primary"
                className={style.btnMob}
              >
                Free with annual Optacheck
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};
