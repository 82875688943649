import React from "react";
import { Cards } from "./Cards";
import style from "./ManCards.module.css";
import phone from "../../Images/phone-ic.svg";
import chip from "../../Images/chip-ic.svg";
import lock from "../../Images/lock-ic.svg";
import file from "../../Images/file-ic.svg";
import picture from "../../Images/picture-ic.svg";
import { useMediaQuery } from "@material-ui/core";

export const ManCards = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={style.row1}>
        <Cards
          icon={phone}
          title="Management devices"
          text="The mobile devices of your collaborators will only have work apps."
        ></Cards>
        <Cards
          icon={chip}
          title="Management from web panel"
          text="Update, install or remove apps from your collaborators' corporate devices silently."
        ></Cards>
        <Cards
          icon={lock}
          title="Security and Loss of devices"
          text="You can locate, block, restart or factory restore the corporate device in case of loss. It asks the user to use a mandatory password."
        ></Cards>
      </div>
      <div className={style.row2}>
        <Cards
          icon={file}
          title="Logs and connectivity management"
          text="Get real-time reports of the log on and / or off of the phone and peripherals. Set rules and force peripherals like GPS, Wi-Fi and Bluetooth to always be on."
        ></Cards>
        <Cards
          icon={picture}
          title="Access to photos and contacts"
          text="The photos from the commercial device will be uploaded to the cloud. Don't lose your client portfolio, you can upload or update the contacts (VCF) to the phone from the web panel."
        ></Cards>
      </div>
    </div>
  );
};
