import React from "react";
import style from "./Cards.module.css";
import { useMediaQuery } from "@material-ui/core";

export const Cards = ({ icon, title, text }) => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.card : style.cardMob}>
        <img src={icon} alt="icon" className={style.img} />
        <h4 className={!mobile ? style.title : style.titleMob}>{title}</h4>
        <p className={!mobile ? style.text : style.textMob}>{text}</p>
      </div>
    </div>
  );
};
