import React from "react";
import style from "./Desktop.module.css";
import mac from "../../Images/mac.png";
import { useMediaQuery } from "@material-ui/core";

export const Desktop = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.item1 : style.item1Mob}>
        <div className={style.txtCont}>
          <h2 className={!mobile ? style.title : style.titleMob}>
            Application Tracker, what is it and how does it work?
          </h2>
          <p className={!mobile ? style.txtSmall : style.txtSmallMob}>
            Our web platform helps you to better control the real working time
            of your team and the use of their computers. It collects information
            and is able to report specific actions such as the execution time of
            the applications, the activity of the keyboard, the regions of the
            screen that receive the most clicks, among other things.
          </p>
          <h4 className={!mobile ? style.subTitle : style.subTitleMob}>
            Desktop and laptop
          </h4>
          <p className={!mobile ? style.txtSmall : style.txtSmallMob}>
            Analyze the use of computers and the corporate network
          </p>
          <div>
            <p className={style.list1}>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
              <p className={!mobile ? style.txtSmall : style.txtSmallMob}>
                Keyboard and screen heat maps
              </p>
            </p>
            <p className={style.list1}>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
              <p className={!mobile ? style.txtSmall : style.txtSmallMob}>
                Power on uptime
              </p>
            </p>
            <p className={style.list1}>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
              <p className={!mobile ? style.txtSmall : style.txtSmallMob}>
                Bandwidth usage
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className={!mobile ? style.item2 : style.item2Mob}>
        <img
          src={mac}
          alt="mac"
          className={!mobile ? style.laptop : style.laptopMob}
        />
        <div className={!mobile ? style.blue : style.blueMob}></div>
      </div>
    </div>
  );
};

/* <img
          src={mac}
          alt="mac"
          className={!mobile ? style.laptop : style.laptopMob}
        />  */
