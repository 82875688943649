import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./Slider.css";
import { Slide1 } from "./Slide1";

export const Slider = (props) => {
  const [pause, setPause] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const timer = React.useRef();

  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    initial: 0,
    controls: false,
    duration: 1000,
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true);
    });
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 4500);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <div className="navigation-wrapper">
      <div ref={sliderRef} className="keen-slider">
        <div className="keen-slider__slide number-slide1">
          <Slide1></Slide1>
        </div>
        <div className="keen-slider__slide number-slide2">
          <Slide1></Slide1>
          {/*Go to the component styles to change the img*/}
        </div>
        <div className="keen-slider__slide number-slide3">
          <Slide1></Slide1>
          {/*Go to the component styles to change the img*/}
        </div>
      </div>

      {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
