import React from "react";
import Button from "@material-ui/core/Button";
import style from "./Proc.module.css";
import clock from "../../Images/clock.svg";
import { useMediaQuery } from "@material-ui/core";

export const Proc = () => {
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={style.display}>
        <img src={clock} alt="hyperlink-icon" />
        <h3 className={!mobile ? style.txt : style.txtMob}>
          How much does the
          <span className={style.blueTxt}> procrastination </span> of
          <br />
          your team cost you?
        </h3>
        <Button
          className={!mobile ? style.btn : style.btnMob}
          variant="contained"
          color="primary"
        >
          Know that with Optalock
        </Button>
      </div>
    </div>
  );
};
