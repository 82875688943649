import React from "react";
import style from "./AppTrack.module.css";
import android from "../../Images/android.png";
import metric from "../../Images/metric.png";
import { useMediaQuery } from "@material-ui/core";

export const AppTrack = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={style.item1}>
        <div className={!mobile ? style.card1 : style.card1Mob}>
          <div className={!mobile ? style.android : style.androidMob}>
            <img src={android} className={style.androidImg} alt="android" />
          </div>
          <h5 className={!mobile ? style.title : style.titleMob}>
            Android MDM
          </h5>
          <p className={!mobile ? style.txt : style.txtMob}>
            Control the use time of your corporate phones and restrict the
            applications that can be used
          </p>
        </div>
      </div>

      <div className={!mobile ? style.item2 : style.item2Mob}>
        <div className={!mobile ? style.card : style.cardMob}>
          <div className={!mobile ? style.metric : style.metricMob}>
            <img src={metric} alt="metric" className={style.metricImg} />
          </div>
          <h5 className={!mobile ? style.title : style.titleMob}>
            Application Tracker
          </h5>
          <p className={!mobile ? style.txt : style.txtMob}>
            Take better control of your team's real working time and monitor
            their computer use.
          </p>
        </div>
      </div>
      <div className={!mobile ? style.item3 : style.item3Mob}>
        <h2 className={!mobile ? style.title3 : style.title3Mob}>
          Do you provide your workers with cell phones and
          <strong> corporate computers?</strong>
        </h2>
        <p className={!mobile ? style.subTitle : style.subTitleMob}>
          Secure and monitor your assets through our web panel and achieve your
          work goals by actively supervising the correct use of the devices.
        </p>
        <ul>
          <p className={style.list1}>
            <p className={!mobile ? style.textSmall : style.textSmallMob}>
              Aumenta la productividad de tu equipo corporativo.
            </p>{" "}
            <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
          </p>
          <p className={style.list1}>
            <p className={!mobile ? style.textSmall : style.textSmallMob}>
              Convierte sus teléfonos en una poderosa herramienta dedica
              exclusivamente al trabajo.
            </p>
            <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
          </p>
          <p className={style.list1}>
            <p className={!mobile ? style.textSmall : style.textSmallMob}>
              Conoce el tiempo de uso por aplicación.
            </p>
            <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
          </p>
          <p className={style.list1}>
            <p className={!mobile ? style.textSmall : style.textSmallMob}>
              Genera insights sobre el uso de teléfonos desde el web panel en
              tiempo real.
            </p>
            <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
          </p>
        </ul>
      </div>
    </div>
  );
};
