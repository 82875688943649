import React, { useState } from "react";
import style from "./Navbar.module.css";
import Button from "@material-ui/core/Button";
import { Link, useMediaQuery } from "@material-ui/core";
import logo from "../../Images/logo.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export const Navbar = () => {
  const [on, setOn] = useState(false);

  const setMenu = () => {
    setOn(!on);
  };
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? "" : style.imgCont}>
        <img
          src={logo}
          className={!mobile ? style.logo : style.logoMob}
          alt="logo"
        />
      </div>

      {!mobile ? (
        <div className={style.LinkContainer}>
          <Link href={"/#"} className={style.Links}>
            Benefits
          </Link>
          <Link href={"/#"} className={style.Links}>
            Our Services
          </Link>
          <Link href={"/#"} className={style.Links}>
            Pricing
          </Link>
          <Link href={"/#"} className={style.Links}>
            Contact Us
          </Link>
          <Button
            className={style.btn}
            variant="contained"
            disableRipple
            href="#"
            color="primary"
          >
            Log in
          </Button>
        </div>
      ) : (
        <div className={style.MenuMob}>
          <Button
            disableRipple
            href="#"
            variant="contained"
            color="primary"
            onClick={setMenu}
            className={style.btnMenu}
          >
            <MenuRoundedIcon fontSize={"large"}></MenuRoundedIcon>
          </Button>
          <div
            className={on ? style.LinkContainerMob : style.LinkContainerMobOff}
          >
            <Link href={"/#"} className={style.Links}>
              <p className={style.textMob}> Benefits</p>
            </Link>
            <Link href={"/#"} className={style.Links}>
              <p className={style.textMob}>Our Services</p>
            </Link>
            <Link href={"/#"} className={style.Links}>
              <p className={style.textMob}> Pricing</p>
            </Link>
            <Link href={"/#"} className={style.Links}>
              <p className={style.textMob}> Contact Us</p>
            </Link>
            <div>
              <Button
                className={style.btnMob}
                variant="contained"
                disableRipple
                href="#"
                color="primary"
              >
                <p className={style.textMob}> Log in</p>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
