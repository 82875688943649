import React from "react";
import { CardsSecond } from "./CardsSecond";
import style from "./Cards2.module.css";
import computer from "../../Images/computer-screen.svg";
import signal from "../../Images/signal-ic.svg";
import code from "../../Images/code-ic.svg";
import cursor from "../../Images/cursor-ic.svg";
import { useMediaQuery } from "@material-ui/core";

export const Cards2 = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      {!mobile ? (
        <div className={style.catalogue}>
          <CardsSecond
            icon={computer}
            title="Monitor keyboard usage"
            text="Through heat maps on keys and frequency of use by application."
          ></CardsSecond>
          <CardsSecond
            icon={signal}
            title="Bandwidth usage stadistics"
            text="Keep track of bandwidth usage by application."
          ></CardsSecond>
          <CardsSecond
            icon={cursor}
            title="Heatmaps by mouse use and clicks"
            text="Have visibility of the number of clicks triggered by application."
          ></CardsSecond>
          <CardsSecond
            icon={code}
            title="Time in apps and on/off"
            text="Keep track of the use, time and frequency of your applications."
          ></CardsSecond>
        </div>
      ) : (
        <div className={style.catalogueMob}>
          <div className={style.row1}>
            <CardsSecond
              icon={computer}
              title="Monitor keyboard usage"
              text="Through heat maps on keys and frequency of use by application."
            ></CardsSecond>
            <CardsSecond
              icon={signal}
              title="Bandwidth usage stadistics"
              text="Keep track of bandwidth usage by application."
            ></CardsSecond>
          </div>
          <div className={style.row2}>
            <CardsSecond
              icon={cursor}
              title="Heatmaps by mouse use and clicks"
              text="Have visibility of the number of clicks triggered by application."
            ></CardsSecond>
            <CardsSecond
              icon={code}
              title="Time in apps and on/off"
              text="Keep track of the use, time and frequency of your applications."
            ></CardsSecond>
          </div>
        </div>
      )}
    </div>
  );
};
