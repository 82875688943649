import React from "react";
import style from "./HomeOffice.module.css";
import imageIcon from "../../Images/homeicon.png";
import office from "../../Images/office.png";
import { useMediaQuery } from "@material-ui/core";

export const HomeOffice = () => {
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.item1 : style.item1Mob}>
        <div className={style.textCont}>
          <h2 className={!mobile ? style.title : style.titleMob}>
            We are the best solution for Homeoffice
          </h2>
          <p className={!mobile ? "" : style.subtitleMob}>
            We have the necessary tools through our Application Tracker to
            ensure the productivity and control of your team wherever they are.
            You have no more excuses! Migrate to Homeoffice.
          </p>
          <div className={!mobile ? style.list : style.listMob}>
            <p className={!mobile ? style.text : style.textMob}>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>{" "}
              Measurement, management and control of devices.
            </p>
            <p className={!mobile ? style.text : style.textMob}>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>{" "}
              Easy and intuitive management and administration.
            </p>
            <p className={!mobile ? style.text : style.textMob}>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>{" "}
              Real-time and historical usage and performance visibility.
            </p>
          </div>
        </div>
      </div>

      <div className={!mobile ? style.item2 : style.item2Mob}>
        <img
          src={office}
          alt="office"
          className={!mobile ? style.imgOffic : style.imgOfficMob}
        />
        <div className={style.iconCont}>
          <img src={imageIcon} alt="imageIcon" className={style.icon} />
        </div>
      </div>
    </div>
  );
};
