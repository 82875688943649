import React from "react";
import { useMediaQuery } from "@material-ui/core";
import style from "./Footer.module.css";
import fb from "../../Images/fb.svg";
import ig from "../../Images/ig.svg";
import linked from "../../Images/linked.svg";
import logo from "../../Images/logo.png";
import { Link } from "@material-ui/core";

export const Footer = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={style.container}>
      <div className={style.container2}>
        <img
          src={logo}
          alt="logo"
          className={!mobile ? style.logo : style.logoMob}
        />
        <div className={!mobile ? style.nav : style.navMob}>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Benefits
          </Link>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Services
          </Link>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Pricing
          </Link>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Contact us
          </Link>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Help
          </Link>
          <Link to="#" className={!mobile ? style.link : style.linkMob}>
            Privacy Policy
          </Link>
        </div>
        <div className={style.ending}>
          <p>
            © 2021 Optalock. All rights reserved -{" "}
            <strong>www.somosopta.com</strong>{" "}
          </p>
          <div className={!mobile ? style.social : style.socialMob}>
            <Link
              rel="noopener noreferrer"
              href="https://www.facebook.com"
              target="_blank"
            >
              <img src={fb} alt="fb" className={mobile ? style.iconsMob : ""} />
            </Link>
            <Link
              rel="noopener noreferrer"
              href="https://www.instagram.com"
              target="_blank"
            >
              {" "}
              <img src={ig} alt="ig" className={mobile ? style.iconsMob : ""} />
            </Link>
            <Link
              rel="noopener noreferrer"
              href="https://www.linkedin.com"
              target="_blank"
            >
              {" "}
              <img
                src={linked}
                alt="linked"
                className={mobile ? style.iconsMob : ""}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
