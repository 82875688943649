import React from "react";
import style from "./RealTime.module.css";
import mac from "../../Images/macfull.png";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";

export const RealTime = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={!mobile ? style.item1 : style.item1Mob}>
        <div className={!mobile ? style.blue : style.blueMob}>
          <img
            src={mac}
            alt="mac"
            className={!mobile ? style.img : style.imgMob}
          />
        </div>
      </div>
      <div className={!mobile ? style.item2 : style.item2Mob}>
        <div className={!mobile ? style.txtCont : style.txtContMob}>
          <h2 className={!mobile ? style.title : style.titleMob}>
            Take real-time control of your staff at the field!
          </h2>
          <p className={!mobile ? style.sub : style.subMob}>
            <strong> Optalock Mobile is FREE </strong> with your Optacheck
            annual plan license!
          </p>
          <div className={style.list}>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Tasks, documentation and digital reports
              </p>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Forms in the field
              </p>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Record by activity and daily route
              </p>{" "}
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Reporting and massive load in Excel of tasks
              </p>
              <span className={!mobile ? style.dot2 : style.dot2Mob}>•</span>
            </p>
            <p className={style.list1}>
              <p className={!mobile ? style.text : style.textMob}>
                Record by activity and daily route
              </p>
              <span className={!mobile ? style.dot1 : style.dot1Mob}>•</span>
            </p>
          </div>
          <div>
            <Button
              variant="contained"
              className={!mobile ? style.btn : style.btnMob}
              color="primary"
            >
              Get started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
