import React from "react";
import style from "./Home.module.css";
import { Control } from "../Components/Control/Control";
import { Navbar } from "../Components/Navbar/Navbar";
import { HowMuch } from "../Components/HowMuch/HowMuch";
import { AppTrack } from "../Components/AppTrack/AppTrack";
import { HomeOffice } from "../Components/HomeOffice/HomeOffice";
import { Proc } from "../Components/Proc/Proc";
import { Mdm } from "../Components/MDM/Mdm";
import { ManCards } from "../Components/ManCards/ManCards";
import { Desktop } from "../Components/Desktop/Desktop";
import { Cards2 } from "../Components/Cards2/Cards2";
import { Stories } from "../Components/Stories/Stories";
import { Slider } from "../Components/Slider/Slider";
import { RealTime } from "../Components/RealTime/RealTime";
import { License } from "../Components/License/License";
import { Contact } from "../Components/Contact/Contact";
import { Footer } from "../Components/Footer/Footer";

export const Home = () => {
  return (
    <div className={style.container}>
      <Navbar></Navbar>
      <Control></Control>
      <HowMuch></HowMuch>
      <AppTrack></AppTrack>
      <HomeOffice></HomeOffice>
      <Proc></Proc>
      <Mdm></Mdm>
      <ManCards></ManCards>
      <Desktop></Desktop>
      <Cards2></Cards2>
      <Stories></Stories>
      <Slider></Slider>
      <RealTime></RealTime>
      <License></License>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
};
