import React from "react";
import style from "./HowMuch.module.css";
import Button from "@material-ui/core/Button";
import linkImg from "../../Images/linkIcon.svg";
import { useMediaQuery } from "@material-ui/core";

export const HowMuch = () => {
  const mobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <div className={style.display}>
        <img src={linkImg} alt="hyperlink-icon" />
        <h3 className={!mobile ? style.txt : style.txtMob}>
          How much does <span className={style.blueTxt}>disconnection</span>{" "}
          cost of 1
          <br /> hour during business hours?
        </h3>
        <Button
          className={!mobile ? style.btn : style.btnMob}
          variant="contained"
          color="primary"
        >
          Know that with Optalock
        </Button>
      </div>
    </div>
  );
};
