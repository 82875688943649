import React, { useState } from "react";
import style from "./License.module.css";
import lic1 from "../../Images/lic1.svg";
import lic2 from "../../Images/lic2.svg";
import lic3 from "../../Images/lic3.svg";
import lic4 from "../../Images/lic4.svg";
import { CardsLicense } from "./CardsLicense";
import { useMediaQuery } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

export const License = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  const [clicked, setClicked] = useState({
    card1: false,
    card2: true,
    card3: false,
    card4: false,
  });

  const [discount, setDiscount] = useState(false);

  const selected1 = (e) => {
    setClicked({ card1: true, card2: false, card3: false, card4: false });
  };

  const selected2 = (e) => {
    setClicked({ card1: false, card2: true, card3: false, card4: false });
  };

  const selected3 = (e) => {
    setClicked({ card1: false, card2: false, card3: true, card4: false });
  };

  const selected4 = (e) => {
    setClicked({ card1: false, card2: false, card3: false, card4: true });
  };

  let initialPrice1 = 3;
  let initialPrice2 = 8;
  let initialPrice3 = 10;
  let initialPrice4 = 15;

  let price1 = initialPrice1 * 12 * 0.75;
  let price2 = initialPrice2 * 12 * 0.75;
  let price3 = initialPrice3 * 12 * 0.75;
  let price4 = initialPrice4 * 12 * 0.75;

  let toggle = () => {
    setDiscount(!discount);
  };

  return (
    <div className={!mobile ? style.container1 : style.container1Mob}>
      <h2 className={!mobile ? style.title : style.titleMob}>
        Get your Optalock license now!
      </h2>
      <div div className={style.item2}>
        <div className={style.switch}>
          <p>Monthly</p>
          <Switch defaultChecked={false} onChange={toggle} />
          <p>Annually</p>
        </div>
        <p className={style.saves}>Saves 25%</p>
      </div>
      {!mobile ? (
        <div className={style.container2}>
          <div onClick={selected1}>
            <CardsLicense
              name="card1"
              img={lic1}
              price={
                !discount
                  ? "$" + initialPrice1 + " / User"
                  : "$" + price1 + " / User"
              }
              title={"Kiosk mode"}
              text1={"- Device with access to a single app."}
              text2={
                "- Useful for retail systems, information kiosks and collections."
              }
              text3={"- Manage the app and the device from our web panel"}
              selected={clicked.card1}
              button={false}
            ></CardsLicense>
          </div>

          <div onClick={selected2}>
            <CardsLicense
              name="card2"
              img={lic2}
              price={
                !discount
                  ? "$" + initialPrice2 + " / User"
                  : "$" + price2 + " / User"
              }
              title={"Mobile MDM"}
              text1={
                "- Monitor and always have control of the corporate device."
              }
              text2={"- Manage and authorize the installation of apps."}
              text3={"- Remotely locate and control your corporate device."}
              selected={clicked.card2}
              button={true}
            ></CardsLicense>
          </div>
          <div onClick={selected3}>
            <CardsLicense
              img={lic3}
              price={
                !discount
                  ? "$" + initialPrice3 + " / User"
                  : "$" + price3 + " / User"
              }
              title={"Desktop and laptop"}
              text1={"- Increase productivity and performance of work teams"}
              text2={"- Useful for control and validation of daily activities."}
              text3={"- Keep track of personnel in Homeoffice."}
              selected={clicked.card3}
              button={false}
            ></CardsLicense>
          </div>

          <div onClick={selected4}>
            <CardsLicense
              img={lic4}
              price={
                !discount
                  ? "$" + initialPrice4 + " / User"
                  : "$" + price4 + " / User"
              }
              title={"Full control"}
              text1={
                "- Monitor and take full control of all your corporate devices."
              }
              text2={
                "- Access to OptaPanel, visibility of field use and devices"
              }
              text3={"- Manage the app and the device from our web panel"}
              selected={clicked.card4}
              button={false}
            ></CardsLicense>
          </div>
        </div>
      ) : (
        <div className={style.container2Mob}>
          <div className={style.column1Mob}>
            <div className={style.fistCard} onClick={selected1}>
              <CardsLicense
                name="card1"
                img={lic1}
                price={
                  !discount
                    ? "$" + initialPrice1 + " / User"
                    : "$" + price1 + " / User"
                }
                title={"Kiosk mode"}
                text1={"- Device with access to a single app."}
                text2={
                  "- Useful for retail systems, information kiosks and collections."
                }
                text3={"- Manage the app and the device from our web panel"}
                selected={clicked.card1}
                button={false}
              ></CardsLicense>
            </div>

            <div onClick={selected2}>
              <CardsLicense
                name="card2"
                img={lic2}
                price={
                  !discount
                    ? "$" + initialPrice2 + " / User"
                    : "$" + price2 + " / User"
                }
                title={"Mobile MDM"}
                text1={
                  "- Monitor and always have control of the corporate device."
                }
                text2={"- Manage and authorize the installation of apps."}
                text3={"- Remotely locate and control your corporate device."}
                selected={clicked.card2}
                button={true}
              ></CardsLicense>
            </div>
          </div>
          <div className={style.column2Mob}>
            <div className={style.fistCard} onClick={selected3}>
              <CardsLicense
                img={lic3}
                price={
                  !discount
                    ? "$" + initialPrice3 + " / User"
                    : "$" + price3 + " / User"
                }
                title={"Desktop and laptop"}
                text1={"- Increase productivity and performance of work teams"}
                text2={
                  "- Useful for control and validation of daily activities."
                }
                text3={"- Keep track of personnel in Homeoffice."}
                selected={clicked.card3}
                button={false}
              ></CardsLicense>
            </div>

            <div onClick={selected4}>
              <CardsLicense
                img={lic4}
                price={
                  !discount
                    ? "$" + initialPrice4 + " / User"
                    : "$" + price4 + " / User"
                }
                title={"Full control"}
                text1={
                  "- Monitor and take full control of all your corporate devices."
                }
                text2={
                  "- Access to OptaPanel, visibility of field use and devices"
                }
                text3={"- Manage the app and the device from our web panel"}
                selected={clicked.card4}
                button={false}
              ></CardsLicense>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
