import React from "react";
import style from "./Contact.module.css";
import Button from "@material-ui/core/Button";
import chat from "../../Images/chat-ic.svg";
import { useMediaQuery } from "@material-ui/core";

export const Contact = () => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <img src={chat} alt="chat" className={style.img} />
      <h2 className={!mobile ? style.title : style.titleMob}>
        Do you have any questions? <br />
        <span className={style.blue}>Contact us</span>
      </h2>

      <p className={!mobile ? style.mail : style.mailMob}>hola@optagonal.com</p>
      <div className={!mobile ? style.form : style.formMob}>
        <input
          type="text"
          id="full-name"
          placeholder="Full name"
          name="full-name"
        />
        <input
          type="text"
          id="contact-number"
          placeholder="Contact number"
          name="phone-number"
        />
        <input type="email" id="email" placeholder="Email" name="email" />
        <Button
          variant="contained"
          color="primary"
          className={!mobile ? style.btn : style.btnMob}
        >
          Get in touch
        </Button>
      </div>
    </div>
  );
};
