import React from "react";
import style from "./MiniCard.module.css";
import { useMediaQuery } from "@material-ui/core";

export const MiniCard = ({ img, txt }) => {
  const mobile = useMediaQuery("(max-width:768px)");
  return (
    <div className={!mobile ? style.container : style.containerMob}>
      <img
        src={img}
        alt="mini"
        className={!mobile ? style.img : style.imgMob}
      />
      <p className={!mobile ? style.txt : style.txtMob}> {txt}</p>
    </div>
  );
};
